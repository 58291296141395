import React from 'react'
import SEO from '../components/seo/seo'
import Contact from '../components/contact/contact'
import Disclaimer from '../components/disclaimer/disclaimer'

const ContactPage = () => (
  <>
    <SEO
      title="Contact"
      description="Contact Lawyer Jim Feleen for personal injury, malpractice, general practice, real estate and other legal representation. 603-540-6507"
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Contact />
    <Disclaimer />
  </>
)

export default ContactPage
